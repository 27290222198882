import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerUpdateService {
  public swReady = false;

  constructor(private updates: SwUpdate, private notification: NzNotificationService) {}

  init() {
    if (navigator.serviceWorker) {
      void navigator.serviceWorker.ready.then(() => {
        this.swReady = true;
      });
    }

    // when service worker registers itself, it will automatically check for updates
    // subscribe to handle what to do when an update is available
    this.updates.versionUpdates.subscribe((event) => {
      if (event.type === 'VERSION_READY') {
        void this.presentUpdateToast();
      }
    });

    // if the app becomes active (from background), check if there is an update available
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && this.swReady) {
        void this.updates.checkForUpdate();
      }
    });

    // run timer to check for updates on the service worker
    setInterval(() => {
      if (this.updates.isEnabled && document.visibilityState === 'visible' && this.swReady) {
        void this.updates.checkForUpdate();
      }
    }, 300000); // 5 mins
  }

  presentUpdateToast(): void {
    const notification = this.notification.create(
      'info',
      'New version available!',
      'We will refresh the page and update in a few seconds.',
      {
        nzDuration: 10000,
        nzPauseOnHover: false,
      }
    );

    notification.onClose.subscribe(async (_) => {
      await this.updates.activateUpdate(); // need to tell the service worker to activate the update
      window.location.reload(); // on toast dismiss, restart app's webview with new version
    });
  }

  public get isEnabled() {
    return this.updates.isEnabled && this.swReady;
  }
}
